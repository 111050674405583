import React from 'react';

const Section = ({ title, children, imageSrc }) => {
    return (
      <div className="section">
        <div className="content-container">
          <div className="text-container">
            {children}
          </div>
          {imageSrc && (
            <div className="image-container">
              <img src={imageSrc}/>
            </div>
          )}
        </div>
      </div>
    );
  };

export default Section;