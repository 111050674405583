import React, {useState} from 'react'
import {Link} from 'react-scroll'
import logo from './logo.png';

const Header = () => {
    const [headerOpen, setHeaderOpen] = useState(false);

    const toggleHeader = () => setHeaderOpen(!headerOpen);

    return(
        <header className = "header">
            <div className="logo">
            <Link to="Home" smooth={true} duration={500} onClick={toggleHeader}>
                <img src={logo} alt="Logo" />
            </Link>
            </div>
            
            <div className = "hamburger" onClick={toggleHeader}> </div>
            <nav className={`nav ${setHeaderOpen ? 'open' : ''}`}>
                <Link to="AboutMe" smooth={true} duration={500} onClick={toggleHeader}>about me</Link>
                <Link to="section2" smooth={true} duration={500} onClick={toggleHeader}>work experience</Link>
                <Link to="section3" smooth={true} duration={500} onClick={toggleHeader}>projects</Link>
                <Link to="section4" smooth={true} duration={500} onClick={toggleHeader}>contact me</Link>
            </nav>
        </header>
    )
}

export default Header;