import './App.css';
import React from 'react';
import Header from './components/Header';
import Section from './components/Section';
import { Element } from 'react-scroll';
import logo from './components/logo.png';
import me from './components/me.png';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

function App() {

  return (
    <div className='App'>
      <Header />
      <Element name = "Home"><Section title="Home">
      <p style={{ fontSize: '32px', fontWeight: 'thin' }}>//welcome to my page! my name is</p>
      <h1 className="typing-effect">Kaitlyn Prerost</h1>

      </Section>
      </Element>


      <Element name = "AboutMe"><Section title="About Me" imageSrc={me}>
      <h2>//about me</h2>
        <p>
        Hi! My name is Kaitlyn Prerost and I am an aspiring software engineer. In December of 2023, I graduated from University of Nevada, Las Vegas with a Bachelors of Science in Computer Science. 
        From creating a flash game in middle school, to coding my Tumblr page in high school, to pursuing a degree in computer science in college, I have always had a passion for bringing creative visions to life through innovative technology. 
        I am currently seeking opportunities after graduation, so continue onward to view my experience!
        </p>
      </Section>
      </Element>


      <Element name = "section2"><Section title="Section 2" imageSrc="">
        <h2>//work experience</h2>
        <h3>Gaming Labs International — Engineering Intern</h3>
        <h5>May 2022 - August 2022</h5>
        <p>Primary Test Engineer for historical horse racing projects. Conducted frontend and integration testing, source code review, SAS testing, and created BASH scripts to perform emulation.</p>
        <button class="Button">C#</button>
        <button class="Button">C++</button>
        <button class="Button">BASH</button>
        <button class="Button">Jira</button>
        <button class="Button">Scrum</button>
        <button class="Button">Excel</button>

        <p></p>

        <h3>UNLV — Operating Systems Teacher Assistant</h3>
        <h5>January 2023 - May 2023</h5>
        <p>Assisted students in learning course material such as scheduling, threading, resource allocation, worksheets, and assignments while providing detailed feedback to aid in their learning. Coded multithreading programs and schedulers.</p>
        <button class="Button">C#</button>
        <button class="Button">BASH</button>
        <button class="Button">Linux</button>
        <button class="Button">VirtualBox</button>
        <button class="Button">Git</button>
      </Section>
      </Element>


      <Element name = "section3"><Section title="Section 3">
        <h2>//projects</h2>
        <h3>Math Unity Game – C#</h3>
        <p>Developed a game in Unity to assist high school students with learning systems of linear equations and submitted as a proposal for the National Science Foundation grant. Worked in collaboration
          with 6 people and 3 clients. Coded core concepts such as a built in calculator, password system for different levels, and dialogue sequences. Conducted unit testing, integration testing, and debugging to ensure proper functionality.
        </p>

        <p></p>

        <h3>Website Portfolio – React</h3>
        <p>Created the website you are currently viewing! Used Figma to draft the design, and coded in Visual Studio Code using React. This website is a Single Page Application.</p>

      </Section>
      </Element>
      
      <Element name = "section4"><Section title="Section 4">
        <h2>//contact me</h2>
        <p>If you would like to reach out regarding any opportunities, my email is kp.prerost@gmail.com.<br>
        </br> Thank you for viewing my website!
        </p>

      </Section>
      </Element>

    </div>
  );
}

export default App;
